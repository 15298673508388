import { Context } from "@/context";
import { IVersions } from "@/data/versions.data";
import { FC, useContext, useEffect, useState } from "react";
import Conditional from "../Conditional";
import { ColorChanger } from "../color-changer";
import scssStyles from "@/utils/scssStyles";
import CTAButton from "../cta-button";
import styles from "./versions-informations.module.scss";
import DataLayer from "@/utils/Datalayer";
import Image from "next/image";

interface CarInfoProps {
  car?: IVersions;
}

export const VersionsInformation: FC<CarInfoProps> = ({ car }) => {
  const { state, dispatch } = useContext(Context);
  const [dataReady, setDataReady] = useState(false);
  const [price, setPrice] = useState<any>();

  const avaiablePrice = async (mvsCode: string) => {
    const isUltra = mvsCode === "2261AP1";
    const dataPrice = await fetch(
      `https://price-service.k8s.fcalatam.com.br/price?mvsCode=${mvsCode}&year=2025&country=BR&state=SP&excludeParts=true`
    );
    const priceResponse = await dataPrice.json();

    dispatch({
      type: " SET_CURRENT_VERSION_PRICE",
      payload: { currentVersionPrice: priceResponse.price },
    });

    setPrice(priceResponse.price);

    dispatch({
      type: "SET_REQUEST_FINISHED",
      payload: { requestFinished: true },
    });

    return dataPrice;
  };

  useEffect(() => {
    let mvs = state.layout.currentVersion.mvsCode;
    if (!mvs) mvs = car?.mvsCode;

    avaiablePrice(mvs);
    setDataReady(true);
  }, [state.layout.currentVersion]);

  if (!dataReady) {
    return <>Carregando</>;
  }

  return (
    <div>
      <div
        key={car?.id}
        className={scssStyles([styles.container, styles.info])}
      >
        <Conditional notOn="desktop">
          <ColorChanger activeCarIndex={car?.id ? car.id : 0} />
        </Conditional>

        <div className={styles.optional}>
          <div className={styles.title}>
            <p>FIAT TORO</p>
            <h3>{car?.model.fullname}</h3>
          </div>
          {/* <p>{car?.description}</p> */}
          <div className={styles.features}>
            {car?.features.map((data) => {
              return (
                <div key={data.alt} className={styles.featureBox}>
                  <Image
                    alt={data.alt}
                    src={data.mobile.fullPath2x}
                    width={312}
                    height={264}
                    objectFit="cover"
                  />
                  <div className={styles.featureDescription}>
                    <p>{data.label}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Conditional notOn="mobile">
          {" "}
          <ColorChanger activeCarIndex={car?.id ? car.id : 0} />
        </Conditional>

        <div className={styles.price}>
          <div className={styles.payment}>
            <p>A partir de</p>
            <p>
              {!price ? (
                <strong>R$ --.---,--</strong>
              ) : (
                <strong>
                  {price.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }) ?? "00.00,00"}
                </strong>
              )}
            </p>
          </div>

          <div className={styles.ctas}>
            <div className={styles.ctaLinks}>
              {car?.versionCtaButton.map((item) => {
                return (
                  <CTAButton
                    key={`menu-cta-${item.id}`}
                    href={item.url}
                    className={scssStyles([styles.ctaButton])}
                    text={item.label}
                    title={item.label}
                    iconCode={item.iconCode}
                    handleClick={() =>
                      DataLayer.clickEvent({
                        element: item.label,
                        elementCategory: "cta",
                        pageSection: "versao",
                        pageSubsection: car.slug,
                      })
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
