import {
  LogoStories,
  ResponsiveLazyImage,
  SectionElement,
  Stories,
} from '@components/index';
import { FC } from 'react';
import styles from './stories-section.module.scss';
import { observer } from 'mobx-react-lite';
import { dataMenuLabels } from '@/data/menu.data';
import {
  dataHero,
  IHero,
  storiesFirstAltTag,
  storiesFirstBackground,
  storiesFirstTitleTag,
  storiesHeader,
} from '@/data/hero.data';
import { stamps } from '@/assets';
import scssStyles from '@/utils/scssStyles';
import SotiesDescriptionOrnament from '@/components/svg-components/StoriesDescriptionOrnament';
import Image from 'next/image';
import LiderAbsolutaSvg from '@/components/svg-components/lider-absoluta-4-anos';

const MainStories: FC = observer(() => {
  const items: IHero[] | any = [];
  items.push({
    showNext: true,
    title: storiesFirstTitleTag,
    body: (
      <div className={styles.slides}>
        <div
          className={styles.slidesContainer}
          aria-label={storiesFirstTitleTag}
          title={storiesFirstTitleTag}
        >
          <div className={styles.topBox}>
            <div className={styles.wrapLogo}>
              <div className={styles.logo}>
                <Image
                  src={storiesHeader.fullPath2x}
                  alt="Nova Fiat"
                  layout='fill'
                />
              </div>

              <div className={styles.svgContainer}>
                <LiderAbsolutaSvg />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    scrollCtaOrientation: 'vertical',
    background: {
      src: storiesFirstBackground,
      alt: storiesFirstAltTag,
    },
    durationInS: 15,
  });
  dataHero.map((item) =>
    items.push({
      showNext: true,
      next: item.title,
      title: item.titleStories,
      description: item.descriptionStories,
      body: (
        <>
          <div className={styles.slides}>
            <div className={styles.slidesContainer}>
              <div
                className={scssStyles([
                  styles.bottomBox,
                  item.haveSmall ? styles.haveSmall : '',
                ])}
              >
                <h2>{item.titleStories}</h2>
                <div>
                  <SotiesDescriptionOrnament />
                  <p className={styles.contentText}>
                    {item.descriptionStories}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      scrollCtaOrientation: 'vertical',
      background: {
        src: item.backgrounds[1],
        alt: item.altTag,
      },
      durationInS: 15,
    })
  );
  return (
    <SectionElement
      id="mobileStories"
      navReference={dataMenuLabels()[0]}
      className={styles.container}
    >
      <Stories items={items} />
    </SectionElement>
  );
});

export default MainStories;
