import {init, Event} from "@sentry/nextjs";

try {
	const addTag = (event: Event) => {
		event.fingerprint = ["tracking-error"];
		const tags = event.tags ?? {};
		return {...tags, 'thirdParty': 'tracking'};
	}
	init({
    dsn: "https://4b948bd96721450a9ca698e6c6a41b31@o211911.ingest.sentry.io/5997672",
    denyUrls: [
      /(^|^[^:]+:\/\/.*|[^\.]+\.)dcode\.works(\/)?/i,
      /^file:\/\//i,
      /^extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^moz-extension:\/\//i,
      /^graph\.facebook\.com/i,
      /^connect\.facebook\.net\/en_US\/all\.js/i,
    ],
    beforeSend(event) {
      const matchRegex =
        /clarity|doubleclick|dbclick|google-analytics|googletagmanager|google|analytics|gtm/gi;
      if (event.breadcrumbs?.find(b => matchRegex.test(b?.message ?? ""))) {
        event.tags = addTag(event);
      }
      return event;
    },
    tracesSampleRate: 0.1,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications",
      "Illegal constructor",
    ],
  });
} catch (error) {
	console.log(error);
}
